<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-equip-class
            :editable="editable"
            type="search"
            label="설비유형"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-equip
            label="설비"
            type="search"
            placeholder="전체"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            codeGroupCd="MDM_EQUIP_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="grade"
            label="중요도"
            v-model="searchParam.grade"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept label="관리부서" type="search" name="managementDepts" v-model="searchParam.managementDepts" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비 중요도 및 법정점검주기"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="equipmentTypeCd"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- <c-btn label="등록" icon="add_circle" /> -->
          <c-btn label="출력" icon="print" @btnClicked="btnPrint" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'important-check-cycle',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
        ],
        columns: [
          {
            label: '설비유형',
            align: 'center',
            child: [
              {
                name: 'upEquipmentTypeName',
                field: 'upEquipmentTypeName',
                label: '대분류',
                style: 'width:160px', 
                align: 'center',
                sortable: false
              },
              {
                name: 'equipmentTypeName',
                field: 'equipmentTypeName',
                label: '중분류',
                style: 'width:160px', 
                align: 'center',
                sortable: false,
              },
            ]
          },
          {
            name: 'equipmentCdName',
            field: 'equipmentCdName',
            label: '해당설비명(관리번호)',
            style: 'width:180px',
            align: 'left',
            sortable: false,
            fix: true,
            type: "link",
          },
          {
            name: 'checkCycle',
            field: 'checkCycle',
            label: '법정점검주기',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'dailyInspection',
                field: 'dailyInspection',
                label: '일상',
                align: 'center',
                style: 'width:45px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'twoWeeks',
                field: 'twoWeeks',
                label: '2주',
                align: 'center',
                style: 'width:45px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'oneMonth',
                field: 'oneMonth',
                label: '1개월',
                style: 'width:45px',
                align: 'center',
                sortable: false,
                // type: "link",
              },
              {
                name: 'twoMonth',
                field: 'twoMonth',
                label: '2개월',
                align: 'center',
                style: 'width:45px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'sixMonth',
                field: 'sixMonth',
                label: '6개월',
                align: 'center',
                style: 'width:45px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'annualRemuneration',
                field: 'annualRemuneration',
                label: '연간',
                align: 'center',
                sortable: false,
                style: 'width:45px',
                // type: "link",
              },
            ]
          },
          {
            label: '정비구분',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'operFlag',
                field: 'operFlag',
                label: '운전',
                align: 'center',
                style: 'width:50px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'pmFlag',
                field: 'pmFlag',
                label: '예방',
                align: 'center',
                style: 'width:50px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'predictiveFlag',
                field: 'predictiveFlag',
                label: '예측',
                align: 'center',
                style: 'width:50px',
                sortable: false,
                // type: "link",
              },
              {
                name: 'cmFlag',
                field: 'cmFlag',
                label: '고장',
                align: 'center',
                style: 'width:50px',
                sortable: false,
                // type: "link",
              },
            ]
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: false,
            // rowspan: 5,
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            style: 'width:120px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            style: 'width:120px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        grade: null,
        inspectionCycleCd: null,
        equipmentCheckTypeCd: null,
        managementDepts: '',
        equipmentTypeCd: '',
        equipmentCd: '',
      },
      editable: true,
      listUrl: '',
      printUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.sop.min.equipment.checkCycle.list.url;
      this.printUrl = selectConfig.sop.min.equipment.checkCycle.print.url;
      this.grid.columns.splice(3, 0, {
        name: 'grade',
        field: 'grade',
        label: '중요도',
        style: 'width:80px',
        type: 'tag',
        colorItems: [
          { stepperMstCd: 'MEGC000001', stepperMstNm: this.$language('A등급'), colorClass: 'blue' },
          { stepperMstCd: 'MEGC000005', stepperMstNm: this.$language('B등급'), colorClass: 'green' },
          { stepperMstCd: 'MEGC000010', stepperMstNm: this.$language('C등급'), colorClass: 'orange' },
          { stepperMstCd: 'MEGC000015', stepperMstNm: this.$language('D등급'), colorClass: 'gray' },
        ],
        align: 'center',
        sortable: false,
      },)
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },
      );
    },
    linkClick(row, col) {
      this.equipmentPopup(row, col);
    },
    equipmentPopup(result, col) {
      if (!col.value) return;
      let param = null;
      // 설비마스터 상세
      if (col.name === 'equipmentCdName') {
        param = { 
          equipmentCd: result.equipmentCd,
        }
        this.popupOptions.title = '설비마스터 상세'; 
        this.popupOptions.target = () =>
        import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.param = param;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
      } 
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    btnPrint() {
      let thisVue = this;
      this.$http.url = this.printUrl
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request(
        _result => {
          let fileOrgNm = this.$language('설비 중요도 및 법정점검주기') + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
